import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { useTranslation } from 'next-i18next';
import Typography500 from 'views/common/components/UI/Typography500';

const animationItem = keyframes`
  0% { opacity: 0; transform: translateX(-2rem); } 
  3% { opacity: 1; transform: translateX(0); } 
  80% { opacity: 1; transform: translateX(0); } 
  98% { opacity: 0; transform: translateX(0); } 
`;

const StyledBox = styled(Box)(({ theme }) => ({
  '&.announcementBar': {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.common.fogWhite,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    marginTop: theme.spacing(1),
    opacity: '.85',
    [theme.breakpoints.down('md')]: {
      borderTop: '1px solid rgba(0,0,0,0.05)',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.dark,
      borderRadius: theme.spacing(0),
      height: theme.spacing(10),
    },
    '& .announcementItem': {
      color: theme.palette.common.fogWhite,
      opacity: 0,
      transform: 'translateX(-2rem)',
      animation: `${animationItem} 5s ease-in infinite`,
      fontSize: theme.spacing(1.75),
      [theme.breakpoints.down('md')]: {
        color: theme.palette.primary.dark,
        height: theme.typography.pxToRem(40),
      },
      [theme.breakpoints.down('sm')]: {
        color: theme.palette.primary.dark,
        height: theme.typography.pxToRem(50),
      },
      [theme.breakpoints.down('xs')]: {
        color: theme.palette.primary.dark,
        height: theme.typography.pxToRem(70),
      },
    },
    '& .announcementIcon': {
      color: theme.palette.primary.main,
      paddingRight: theme.spacing(0.5),
    },
  },
  '&.announcementBarVertical': {
    borderTop: '1px solid rgba(0,0,0,0.05)',
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(10),
    '& .announcementItem': {
      color: theme.palette.primary.dark,
    },
  },
}));

const AnnouncementBar = (props) => {
  const { t } = useTranslation('fe_er_common_main_widget');
  const {
    vertical,
    slidingMessages = [
      t('fe_er_common_main_widget:save_even_more'),
      t('fe_er_common_main_widget:future_bookings'),
      t('fe_er_common_main_widget:can_ride_rounded_trip_or_one_way'),
    ],
  } = props;
  const [itemOrder, setItemOrder] = useState(0);
  const [unmount, setUnmount] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setUnmount(true);
      setItemOrder((prevValue) => (prevValue + 1) % slidingMessages.length);
      setUnmount(false);
    }, 5000);
  }, []);

  return (
    <StyledBox
      className={`announcementBar ${vertical ? 'announcementBarVertical' : ''}`}
      vertical={vertical}>
      {!unmount && (
        <>
          <LocalFireDepartmentIcon className="announcementIcon" />
          <Typography500
            component="p"
            varient="h6"
            className="announcementItem">
            {slidingMessages[itemOrder]}
          </Typography500>
        </>
      )}
    </StyledBox>
  );
};
export { AnnouncementBar };
