import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  Autocomplete,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { AutoCompleteMenu } from 'views/common/components/UI/MainWidget/AutoCompleteMenu';
import { TOUR_WIDGET_FIELDS } from 'lib/utils/mainWidgetConstants';
import StyledGrid, {
  StyledMainWidgetTextField,
} from 'views/common/components/UI/MainWidget/TourWidget/TourWidget.styles';

const TourWidget = (props) => {
  const { t } = useTranslation('fe_er_common_main_widget');
  const {
    // vertical, only render the horizontal variant until changed later
    countriesList,
    toursList,
    handleSelectInputChange,
    selectedOptions,
    handleSearchButtonClick,
    renderAllWidget = true,
    handleRenderAllWidget = () => {},
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [widgetMounted, setWidgetMounted] = useState(false);
  const getFieldValue = (options, selectedOptionField) => options.find(
    (option) => option.id === selectedOptions[selectedOptionField]?.id,
  ) || {};
  useEffect(() => {
    setWidgetMounted(true);
  }, []);
  useEffect(() => {
    if (widgetMounted && !isMobile) handleRenderAllWidget(true);
  }, [widgetMounted]);

  return (
    <StyledGrid
      container
      rowSpacing={1}
      direction="column"
      className="tourWidget">
      <Grid item xs>
        <Box className="TWFieldContainer">
          <Autocomplete
            options={countriesList}
            getOptionLabel={(option) => option.name || ''}
            value={getFieldValue(countriesList, TOUR_WIDGET_FIELDS.COUNTRY)}
            onChange={async (...args) => {
              await handleSelectInputChange(
                TOUR_WIDGET_FIELDS.COUNTRY,
                ...args,
              );
              handleRenderAllWidget(true);
            }}
            autoComplete
            includeInputInList
            PaperComponent={AutoCompleteMenu}
            renderInput={(params) => (
              <StyledMainWidgetTextField
                {...params}
                label={t('fe_er_common_main_widget:country')}
                variant="filled"
              />
            )}
          />
        </Box>
      </Grid>
      {renderAllWidget && (
        <>
          <Grid item xs>
            <Box>
              <Autocomplete
                options={toursList}
                getOptionLabel={(option) => option.name || ''}
                autoComplete
                includeInputInList
                value={getFieldValue(toursList, TOUR_WIDGET_FIELDS.TOUR)}
                onChange={(...args) => handleSelectInputChange(TOUR_WIDGET_FIELDS.TOUR, ...args)}
                PaperComponent={AutoCompleteMenu}
                renderInput={(params) => (
                  <StyledMainWidgetTextField
                    {...params}
                    label={t('fe_er_common_main_widget:tour_name')}
                    variant="filled"
                  />
                )}
                renderOption={(optionProps, option) => (
                  <li {...optionProps} key={option.id}>
                    {option.name}
                  </li>
                )}
              />
            </Box>
          </Grid>
        </>
      )}
      <Grid item xs>
        <Button
          variant="contained"
          size="large"
          fullWidth
          disableElevation
          onClick={() => {
            if (renderAllWidget) handleSearchButtonClick();
            else handleRenderAllWidget(true);
          }}>
          {renderAllWidget
            ? t('fe_er_common_main_widget:search_now')
            : t('fe_er_common_main_widget:start_your_reservation')}
        </Button>
      </Grid>
    </StyledGrid>
  );
};

export { TourWidget };
